export default [
    {
        date: '2023-05-12',
        events: [
            {
                id: 151845,
                type: 'Лекция',
                typeOfEvent: 'События высшего образования',
                eventBegin: '9:30',
                eventEnd: '11:00',
                caption: 'Управление непрофильными и неэффективными объектами недвижимости',
                direction: 'Экономика недвижимости',
                venue: 'Ссылка на проведение',
                venueLink: '#',
                auditorium: 'online',
                auditoriumLink: '#',
                teacherRang: 'Ст. преподаватель',
                teacherName: 'Новичков Алексей Владимирович'
            },
            {
                id: 1545,
                type: 'Практика',
                typeOfEvent: 'События высшего образования',
                eventBegin: '11:20',
                eventEnd: '12:50',
                caption: 'Управление профильными и эффективными объектами недвижимости и движимости',
                direction: 'Экономика недвижимости',
                venue: 'главный корпус ВШЭ',
                venueLink: '',
                auditorium: 'Ауд. 434',
                auditoriumLink: '',
                teacherRang: 'Мл. преподаватель',
                teacherName: 'Иванов Иван Иванович'
            }
        ]
    },
    {
        date: '2023-05-13',
        events: [
            {
                id: 151847,
                type: 'Лекция',
                typeOfEvent: 'События высшего образования',
                eventBegin: '9:30',
                eventEnd: '11:00',
                caption: 'Управление непрофильными и неэффективными объектами недвижимости',
                direction: 'Экономика недвижимости',
                venue: 'Ссылка на проведение',
                venueLink: '#',
                auditorium: 'online',
                auditoriumLink: '#',
                teacherRang: 'Ст. преподаватель',
                teacherName: 'Новичков Алексей Владимирович'
            },
            {
                id: 151848,
                type: 'Практика',
                typeOfEvent: 'События высшего образования',
                eventBegin: '18:20',
                eventEnd: '19:50',
                caption: 'Управление профильными и эффективными объектами недвижимости и движимости',
                direction: 'Экономика недвижимости',
                venue: 'главный корпус ВШЭ',
                venueLink: '',
                auditorium: 'Ауд. 434',
                auditoriumLink: '',
                teacherRang: 'Мл. преподаватель',
                teacherName: 'Иванов Иван Иванович'
            }
        ]
    },
    {
        date: '2023-04-14',
        events:[
        {
                id: 150245,
                type: 'Субботник',
                typeOfEvent: 'Общественные события',
                eventBegin: '11:30',
                eventEnd: '14:00',
                caption: 'Управление непрофильными и неэффективными объектами недвижимости',
                direction: 'Экономика недвижимости',
                venue: 'Ссылка на проведение',
                venueLink: '#',
                auditorium: 'online',
                auditoriumLink: '#',
                teacherRang: 'ЗавХоз',
                teacherName: 'Стипаныч'
            },
        ]
    },
    {
        date: '2023-04-24',
        events: [
            {
                id: 151847,
                type: 'Лекция',
                typeOfEvent: 'Начало учебной программы ДПО',
                eventBegin: '9:30',
                eventEnd: '11:00',
                caption: 'Потребление еды в невисомости',
                direction: 'Космические полеты',
                venue: 'Ссылка на проведение',
                venueLink: '#',
                auditorium: 'online',
                auditoriumLink: '#',
                teacherRang: 'Ст. лейтинант',
                teacherName: 'Хлебников Максим Владимирович'
            },
            {
                id: 151848,
                type: 'Практика',
                typeOfEvent: 'События ДПО',
                eventBegin: '11:20',
                eventEnd: '12:50',
                caption: 'Сведение дебитов с кредитами',
                direction: 'Экономика',
                venue: 'главный корпус ВШЭ',
                venueLink: '',
                auditorium: 'Ауд. 434',
                auditoriumLink: '',
                teacherRang: 'Мл. преподаватель',
                teacherName: 'Макарова Инна Сергеевна'
            }
        ]
    },
    {
        date: '2023-04-25',
        events: [
            {
                id: 151598,
                type: 'Лекция',
                typeOfEvent: 'События высшего образования',
                eventBegin: '9:30',
                eventEnd: '11:00',
                caption: 'Управление непрофильными и неэффективными объектами недвижимости',
                direction: 'Экономика недвижимости',
                venue: 'Ссылка на проведение',
                venueLink: '#',
                auditorium: 'online',
                auditoriumLink: '#',
                teacherRang: 'Ст. преподаватель',
                teacherName: 'Новичков Алексей Владимирович'
            },
            {
                id: 152342345,
                type: 'Практика',
                typeOfEvent: 'События высшего образования',
                eventBegin: '11:20',
                eventEnd: '12:50',
                caption: 'Управление профильными и эффективными объектами недвижимости и движимости',
                direction: 'Экономика недвижимости',
                venue: 'главный корпус ВШЭ',
                venueLink: '',
                auditorium: 'Ауд. 434',
                auditoriumLink: '',
                teacherRang: 'Мл. преподаватель',
                teacherName: 'Иванов Иван Иванович'
            },
            {
                id: 1523423444,
                type: 'Концерт',
                typeOfEvent: 'Общественные события',
                eventBegin: '18:00',
                eventEnd: '20:00',
                caption: 'День великой среды во вторник',
                direction: '',
                venue: 'главный корпус ВШЭ',
                venueLink: '',
                auditorium: 'Актовый зал',
                auditoriumLink: '',
                teacherRang: 'Массовик затейник',
                teacherName: 'DJ Apocalypsys'
            }
        ]
    },
    {
        date: '2023-04-26',
        events: [
            {
                id: 151847,
                type: 'Лекция',
                typeOfEvent: 'Начало учебной программы ДПО',
                eventBegin: '9:30',
                eventEnd: '11:00',
                caption: 'Потребление еды в невисомости',
                direction: 'Космические полеты',
                venue: 'Ссылка на проведение',
                venueLink: '#',
                auditorium: 'online',
                auditoriumLink: '#',
                teacherRang: 'Ст. лейтинант',
                teacherName: 'Хлебников Максим Владимирович'
            },
            {
                id: 151848,
                type: 'Практика',
                typeOfEvent: 'События высшего образования',
                eventBegin: '11:20',
                eventEnd: '12:50',
                caption: 'Сведение дебитов с кредитами',
                direction: 'Экономика',
                venue: 'главный корпус ВШЭ',
                venueLink: '',
                auditorium: 'Ауд. 434',
                auditoriumLink: '',
                teacherRang: 'Мл. преподаватель',
                teacherName: 'Макарова Инна Сергеевна'
            }
        ]
    },
    {
        date: '2023-04-27',
        events: [
            {
                id: 15197,
                type: 'Лекция',
                typeOfEvent: 'Начало учебной программы ДПО',
                eventBegin: '9:30',
                eventEnd: '11:00',
                caption: 'Потребление еды в невисомости',
                direction: 'Космические полеты',
                venue: 'Ссылка на проведение',
                venueLink: '#',
                auditorium: 'online',
                auditoriumLink: '#',
                teacherRang: 'Ст. лейтинант',
                teacherName: 'Хлебников Максим Владимирович'
            },
            {
                id: 151870789,
                type: 'Практика',
                typeOfEvent: 'События высшего образования',
                eventBegin: '11:20',
                eventEnd: '12:50',
                caption: 'Полит. экономия',
                direction: 'Экономика',
                venue: 'главный корпус ВШЭ',
                venueLink: '',
                auditorium: 'Ауд. 434',
                auditoriumLink: '',
                teacherRang: 'Мл. преподаватель',
                teacherName: 'Фридрихова Елена Карловна'
            }
        ]
    },
]
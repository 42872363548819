var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper-document acts-component"},[_c('div',{staticClass:"title-number__list"},[_c('div',{staticClass:"data-number"},[_vm._v(" "+_vm._s(_vm.localeText.myDocsYearNum)+" ")]),_c('div',{staticClass:"name-list"},[_vm._v(" "+_vm._s(_vm.localeText.myDocsName)+" ")])]),_c('div',{staticClass:"container-list"},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"item-list__document"},[_c('div',{staticClass:"item-list__document-info"},[_c('div',{staticClass:"item-list__extension"},[(item.additions?.length > 0)?_c('div',{staticClass:"rounded border-solid border-blue flex justify-center items-center cursor-pointer item-list__extension-btn",style:({
              transform: _vm.openAdditionsIds.includes(item.id)
                ? 'rotate(180deg)'
                : 'rotate(0deg)'
            }),on:{"click":function($event){return _vm.toggleAddition(item.id)}}},[_c('ArrowDropdownIcon')],1):_vm._e()]),_c('div',{staticClass:"item-list__number"},[_c('p',[_vm._v(_vm._s(item.year))]),_c('span',[_vm._v(_vm._s(_vm.getItemNumber(item.number)))])]),_c('div',{staticClass:"item-list__content"},[_c('span',[_vm._v(_vm._s(item.type))]),(item.is_expelled)?_c('a',{staticClass:"item-list__expelled"},[_vm._v(" "+_vm._s(_vm.localeText.docListDissolved)+" ")]):_vm._e(),(item.file_url_sign)?_c('a',{staticClass:"item-list__content-tag",attrs:{"href":item.file_url_sign,"download":_vm.getNameByPath(item.file_url_sign),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.localeText.myDocsSignedDigital)+" ")]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(item.description)+" ")])]),_c('div',{staticClass:"item-list__buttons"},[_c('a',{staticClass:"link-btn-upload",attrs:{"target":"_blank","title":_vm.localeText.myDocsUploadRec},on:{"click":function($event){$event.preventDefault();return _vm.openUploadForm(item.originalId)}}}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(item.can_sign),expression:"item.can_sign"}],staticClass:"link-btn-upload scancopy",attrs:{"target":"_blank","title":_vm.localeText.attachScanCopy},on:{"click":function($event){$event.preventDefault();_vm.openAttachScanCopyDialog(
                item.contract_id || item.originalId,
                item.sign_template
              );
              _vm.actId = item.contract_id ? item.originalId : null;}}}),(item.file_url)?_c('a',{staticClass:"link-btn-share",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.copyToClipboard(item.file_url)}}},[_vm._v(" "+_vm._s(_vm.localeText.btnShare)+" ")]):_vm._e(),_c('button',{staticClass:"button button__payment-info",attrs:{"title":_vm.localeText.myDocsPaymentSchedule},on:{"click":function($event){return _vm.onShowModalPay(item.id)}}}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(item.url),expression:"item.url"}],staticClass:"link-btn-download",attrs:{"href":item.url,"download":"","target":"_blank"}})])]),(
          item.additions?.length > 0 && _vm.openAdditionsIds.includes(item.id)
        )?_c('div',{staticClass:"item-list__document-additions"},_vm._l((item.additions),function(addItem){return _c('div',{key:addItem.id,staticClass:"item-list__document-additions-item"},[_c('div',{staticClass:"item-list__document-additions-item-date"},[_vm._v(" "+_vm._s(_vm.parseDate(addItem.date))+" ")]),_c('div',{staticClass:"item-list__document-additions-item-title",staticStyle:{"flex-grow":"1"}},[(addItem.file_url_sign)?_c('a',{staticClass:"item-list__content-tag",attrs:{"href":addItem.file_url_sign,"download":_vm.getNameByPath(addItem.file_url_sign),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.localeText.myDocsSignedDigital)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.localeText.myDocsAdditionAgree)+" №"+_vm._s(addItem.number)+" ")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(addItem.can_sign),expression:"addItem.can_sign"}],staticClass:"link-btn-upload scancopy",staticStyle:{"height":"20px","width":"20px","margin-right":"16px"},attrs:{"target":"_blank","title":_vm.localeText.attachScanCopy},on:{"click":function($event){$event.preventDefault();_vm.openAttachScanCopyDialog(
                item.originalId,
                addItem.sign_template
              );
              _vm.additionalId = addItem.id;}}}),(addItem.file_url)?_c('a',{staticClass:"block",attrs:{"href":addItem.file_url,"target":"_blank"}},[_c('DownloadFileIcon',{staticStyle:{"width":"20px","height":"20px"}})],1):_vm._e()])}),0):_vm._e()])}),0),_c('el-dialog',{staticClass:"acts-component__dialog-payment",attrs:{"title":_vm.localeText.myDocsPaymentSchedule,"visible":_vm.showModalPay,"top":"6vh"},on:{"update:visible":function($event){_vm.showModalPay=$event}}},[(_vm.selectedDocItem)?_c('PaymentInformation',{attrs:{"localization":_vm.localization,"contract-num":_vm.selectedDocItem.number,"contract-id":_vm.selectedDocItem.originalId,"description":_vm.selectedDocItem.description,"cost":_vm.selectedDocItem.price,"modal-visible":_vm.showModalPay,"pay-button":_vm.selectedDocItem.can_pay && _vm.selectedDocItem.need_pay},on:{"click-pay-btn":_vm.onClickPayBtn}}):_vm._e()],1),_c('el-dialog',{attrs:{"title":_vm.localeText.myDocsUploadRec,"visible":_vm.showPopup,"before-close":_vm.clearUploadForm,"destroy-on-close":true},on:{"update:visible":function($event){_vm.showPopup=$event}}},[_c('form',{ref:"uploadForm",attrs:{"action":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.uploadId),expression:"uploadId"}],attrs:{"type":"hidden","name":"contract_id"},domProps:{"value":(_vm.uploadId)},on:{"input":function($event){if($event.target.composing)return;_vm.uploadId=$event.target.value}}}),_c('label',{attrs:{"for":"file_receipt"}},[_c('InputFile',{attrs:{"id":"file_receipt","value":_vm.uploadFile,"label":_vm.localeText.attachFile,"show-alert-info":true,"accept-extensions":[
            'pdf',
            'doc',
            'docx',
            'rtf',
            'odt',
            'jpg',
            'png',
            'webp'
          ]},on:{"on-change":_vm.uploadFileHandler}})],1)]),_c('el-button',{staticStyle:{"margin-top":"20px"},attrs:{"disabled":!_vm.validated,"loading":_vm.uploadLoading,"type":"primary"},on:{"click":_vm.submitUploadForm}},[_vm._v(" "+_vm._s(_vm.localeText.toSend)+" ")])],1),_c('el-dialog',{staticClass:"hse-modal hse-modal__profile-modal hse-modal__indent-y_xl",attrs:{"title":_vm.localeText.attachScanCopy,"visible":_vm.showAttachScanCopyDialog,"destroy-on-close":true,"before-close":_vm.clearUploadScanCopyForm},on:{"update:visible":function($event){_vm.showAttachScanCopyDialog=$event}}},[_c('p',{staticClass:"hse-modal__template-info"},[_vm._v(" "+_vm._s(_vm.localeText.myDocsTemplateInfo)+" ")]),_c('a',{staticClass:"hse-modal__template-link",attrs:{"target":"_blank","download":"","href":_vm.scanCopyFileUrl}},[_c('span',{staticClass:"hse-modal__template-link-icon-clip"}),_c('span',[_vm._v(" "+_vm._s(_vm.localeText.myDocsAgreeTemplate)+" ")]),_c('span',{staticClass:"hse-modal__template-link-icon-import"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.uploadId),expression:"uploadId"}],attrs:{"type":"hidden","name":"contract_id"},domProps:{"value":(_vm.uploadId)},on:{"input":function($event){if($event.target.composing)return;_vm.uploadId=$event.target.value}}}),_c('InputFile',{attrs:{"id":"file","value":_vm.uploadScanCopyFile,"show-alert-info":true,"label":_vm.localeText.attachFile,"accept-extensions":['pdf', 'jpg', 'png']},on:{"on-change":_vm.uploadScanCopyFileHandler}}),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"margin-top":"20px"},attrs:{"disabled":!_vm.validatedScanCopy,"loading":_vm.uploadLoading,"type":"primary"},on:{"click":_vm.submitUploadScanCopyForm}},[_vm._v(" "+_vm._s(_vm.localeText.toSend)+" ")])],1)],1),_c('SelectPaymentOptionsDialog',{ref:"pay_dialog",attrs:{"loading":_vm.sendingPaymentRequest},on:{"submit":_vm.submitPaymentForm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <nav class="aside__nav nav">
    <ul class="nav__list">
      <li
        v-for="link in consolidateMenuLinks"
        :key="link.signature ?? link.id"
        class="nav__item"
      >
        <AbsoluteRelativeLink
          v-if="link.signature == 'elk.notification'"
          :to="link.url.startsWith('/') ? link.url : '/' + link.url"
          active-class="choosen"
          class="nav__link"
          exact
        >
          <el-popover
            v-if="!menuActive"
            :content="link.block_name"
            placement="right"
            trigger="hover"
          >
            <div
              slot="reference"
              class="noty-badge"
            >
              <div class="noty-badge__body">
                <div class="noty-badge__text">
                  <component
                    :is="iconLoader(link.signature)"
                    class="icon nav__icon"
                  />
                  <span class="nav__text">{{ link.anchor_name }}</span>
                </div>
                <div
                  v-if="notificationCount > 0"
                  class="noty-badge__noty"
                >
                  <div class="noty-badge__count js-notif-count">
                    {{ notificationCount }}
                  </div>
                </div>
              </div>
            </div>
          </el-popover>
          <div
            v-else
            class="noty-badge"
          >
            <div class="noty-badge__body">
              <div class="noty-badge__text">
                <component
                  :is="iconLoader(link.signature)"
                  class="icon nav__icon"
                />
                <span class="nav__text">{{ link.anchor_name }}</span>
              </div>
              <div
                v-if="notificationCount > 0"
                class="noty-badge__noty"
              >
                <div class="noty-badge__count js-notif-count">
                  {{ notificationCount }}
                </div>
              </div>
            </div>
          </div>
        </AbsoluteRelativeLink>
        <router-link
          v-else-if="link.signature === 'elk.user_suggestions'"
          :to="link.url.startsWith('/') ? link.url : '/' + link.url"
          active-class="choosen"
          class="nav__link"
          exact
        >
          <template v-if="userHasAccessSuggestions">
            <template v-if="!menuActive">
              <el-popover
                :content="link.block_name"
                placement="right"
                trigger="hover"
              >
                <div slot="reference">
                  <component
                    :is="iconLoader(link.signature)"
                    class="icon nav__icon"
                  />
                </div>
              </el-popover>
            </template>
            <component
              :is="iconLoader(link.signature)"
              v-else
              class="icon nav__icon"
            />
            <span class="nav__text">{{ link.anchor_name }}</span>
          </template>
        </router-link>
        <nav-menu-item-dropdown
          v-else-if="link.children && link.children.length"
          :link="link"
        />

        <template v-else-if="link.ui_item_type === 'header'">
          <div
            v-if="menuActive"
            class="nav_head"
          >
            -- {{ link.block_name }} --
          </div>
          <div
            v-else
            class="dpo_line"
          >
            <div />
          </div>
        </template>
        <template v-else-if="link.signature || link.image">
          <router-link
            v-if="!link.url.includes('https')"
            :to="link.url.startsWith('/') ? link.url : '/' + link.url"
            active-class="choosen"
            class="nav__link"
            exact
          >
            <router-tmpl :link="link" />
          </router-link>
          <a
            v-else
            :href="link.url"
            class="href_link"
          >
            <router-tmpl :link="link" />
          </a>
        </template>
      </li>
      <li
        v-if="isMobile"
        class="nav__item"
      >
        <template v-if="isPublicUser">
          <a
            class="text-gray-300 nav__link"
            href="#3"
            @click.prevent="showSupportNotAuthorizedUser"
          >
            <iconFeedback class="icon nav__icon" />
            <i18n
              :path="
                getCommonCaptionByName('modalSupportNotAuthorizedUserTitle')
              "
              tag="span"
            />
          </a>
        </template>
        <a
          v-else
          class="nav__link"
          href="https://pmo.hse.ru/servicedesk/customer/portal/81/create/1815"
          target="_blank"
        >
          <iconFeedback class="icon nav__icon" />
          <span class="nav__text">{{ $t('supportCaption') }}</span>
        </a>
      </li>
      <li v-if="isTablet" />
    </ul>
    <ElkModalSupportNotAuthorizedUser ref="modalSupport" />
  </nav>
</template>

<script>
import iconLoader from '@/utils/iconLoader';
import iconArrow from '@/assets/icons/menuIcons/arrow.svg?inline';
import {mapState, mapGetters} from 'vuex';
import {IT_SUPPORT} from '@/router/routes.js';
import {getCommonCaptionByName} from '@/i18n/utils';

const FEEDBACK_SIGNATURE = 'elk.feedback';
import iconFeedback from '@/assets/icons/menuIcons/feedbackLine.svg?inline';
import AbsoluteRelativeLink from '@/components/AbsoluteRelativeLink';
import ElkModalSupportNotAuthorizedUser from '@/components/elkHeader/elkModalSupportNotAuthorizedUser.vue';
import Axios from '@/utils/Elk-Axios';
import {GET_ADDITIONAL_PAGE_TREE} from '@/api';
import RouterTmpl from '@/components/elkSidebar/RouterTmpl.vue';
import NavMenuItemDropdown from '@/components/elkSidebar/NavMwnuItemDropdown.vue';

export default {
  name: 'ElkNav',
  components: {
    NavMenuItemDropdown,
    RouterTmpl,
    AbsoluteRelativeLink,
    ElkModalSupportNotAuthorizedUser,
    iconArrow,
    iconFeedback
  },
  data() {
    return {
      IT_SUPPORT,
      consolidateLinks: [],
      additionalMenu: []
    };
  },
  computed: {
    ...mapState({
      menuActive: state => state.menuActive,
      notificationCount: state => state.notificationCounter.notificationCount
    }),
    ...mapGetters([
      'isTablet',
      'isMobile',
      'isPublicUser',
      'userHasAccessSuggestions',
      'userRepresent',
      'consolidateMenuLinks'
    ]),
    getFeedbackWidgetTitle() {
      const feedbackPage = this.$store.state.user.pageTree.find(
        elem => elem.signature === FEEDBACK_SIGNATURE
      );
      return feedbackPage && feedbackPage.block_name;
    }
  },
  methods: {
    iconLoader,
    getCommonCaptionByName,
    showSupportNotAuthorizedUser() {
      this.$refs.modalSupport.openModal();
    },
    async additionalMenuLinks() {
      if (!this.$store.getters.userDpo) return;
      const {data} = await Axios.get(GET_ADDITIONAL_PAGE_TREE + '/main');
      if (data.data.length) {
        const list = [];
        data.data.forEach(item => {
          list.push({
            ...item,
            is_in_menu: true,
            anchor_name: item.title + ' DPO',
            block_name: item.title + ' DPO',
            url: item.link,
            ui_item_type: 'Page',
            widgets: []
          });
        });
        list.forEach(el => {
          this.consolidateLinks.push(el);
        });
        this.additionalMenu = list;
      }
    }
  },
  i18n: {
    messages: {
      en: {
        supportCaption: 'Technical help center'
      },
      ru: {
        supportCaption: 'Единое окно техподдержки'
      }
    }
  }
};
</script>

<style>
.nav_head {
  font-weight: bold;
  text-align: center;
  color: var(--font-color-alt);
}
.dpo_line {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dpo_line div {
  width: 100%;
  height: 2px;
  background-color: #6a7a98;
}
.href_link {
  color: var(--font-color-alt);
}
.href_link:hover {
  color: var(--dark-blue);
}
.nav__link {
  padding-right: 10px;
}
</style>

<template>
  <ErrorPagePublic v-if="isPublicUser" />
  <div
    v-else
    :class="{body: true, active: menuActive}"
  >
    <menu-wrapper :page-name="title" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <section class="selected-programs-section">
          <div class="container">
            <h1 class="page-title">
              {{ title }}
            </h1>

            <program-preset-filter
              :result-count="arRecommendations?.length || 0"
              :load-method="fetchPrograms"
              :loaded="onLoadData"
            />

            <program-grid v-if="arRecommendations.length">
              <program-card
                v-for="program in arRecommendations"
                :key="program.pcId"
                :pc-id="program.pcId"
                :pc-active="program.pcActive"
                :pc-direct="program.pcDirect"
                :pc-caption="program.pcCaption"
                :pc-start="program.pcStart"
                :pc-duration="program.pcDuration"
                :pc-price="program.pcPrice"
                :pc-old-price="program.pcOldPrice"
                :pc-type="program.pcType"
                :pc-favorites="program.pcFavorites"
                :pc-comparison="program.pcComparison"
                :pc-detail-url="program.pcDetailUrl"
                :pc-campus="program.pcCampus"
                :edu-format="program.eduFormat"
                :pc-duration-value="program.pcDurationValue"
                :pc-training-areas="program.pcTrainingAreas"
                @returnFavoriteId="toggleProgramFavorite"
                @returnComparisonId="toggleProgramComparison"
              >
                <!-- @returnComparisonId="(...arg) => { toggleComparisonParent(...arg, arRecommendations) }"  -->
              </program-card>
            </program-grid>
            <show-more-program v-if="arRecommendations.length" />
            <empty-wrapper v-else>
              <empty-data-message :text="emptyRecommended" />
            </empty-wrapper>
          </div>
        </section>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import {formatNumberAsCurrencyString} from '@/utils/formatNumberAsCurrencyString';
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import EmptyWrapper from '@/components/common/elkEmptyWrapper';
import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import ProgramGrid from '@/components/programs/ProgramGrid.vue'; //контейнер для  программ
import ProgramCard from '@/components/programs/Program-card.vue'; //карточка программы
import ProgramPresetFilter from '@/components/filters/presets/ProgramPresetFilter.vue';
import ErrorPagePublic from '@/views/404/index.vue';
import {filterBus} from '@/main';
import sortFilterDrop from '@/utils/sortDate';
import ShowMoreProgram from '@/components/programs/ShowMoreProgram.vue';
import localization from '@/i18n/localization';
import setNotification from '@/utils/setNotification';
import {prepareProgramCardRecommendedView} from '@/utils/prepareProgramCardData';

export default {
  name: 'RecommendedPrograms',
  components: {
    ShowMoreProgram,
    ErrorPagePublic,
    ProgramPresetFilter,
    EmptyDataMessage,
    EmptyWrapper,
    menuWrapper,
    PageFooter,
    'program-grid': ProgramGrid,
    'program-card': ProgramCard
  },

  data() {
    return {
      localization: localization('dpo.programs.recommended'),
      pageClass: 'recommended-programs',
      arRecommendations: []
    };
  },

  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
    ...mapGetters({
      programs: 'programs/recommendations'
    }),
    title() {
      return this.localization.widgetCaption('recommendedPrograms');
    },
    emptyRecommended() {
      return this.localization.widgetCaption('emptyRecommended');
    }
  },
  mounted() {
    filterBus.$on('change', data => {
      let tmp = Array.from(this.arRecommendations);
      tmp = sortFilterDrop(tmp, data);
      this.arRecommendations = Array.from(tmp);
    });
  },

  methods: {
    ...mapActions(['addProgramToFavorites', 'deleteProgramFromFavorites']),
    ...mapActions({
      fetchPrograms: 'programs/fetchRecommendations'
    }),

    /**
     * функция делает промежутки между тысячными в ценах
     * возвращает отформатированную строку
     * @param {Number} price
     */
    formatPrice: function (price) {
      return formatNumberAsCurrencyString(price);
    },
    toggleProgramFavorite(id, isFavorite) {
      for (let program of this.arRecommendations) {
        if (program.pcId === id) return (program.pcFavorites = isFavorite);
      }
    },
    toggleProgramComparison(id, isComparison) {
      for (let program of this.arRecommendations) {
        if (program.pcId === id) return (program.pcComparison = isComparison);
      }
    },
    onLoadData() {
      this.arRecommendations = [];

      try {
        this.arRecommendations = this.programs()?.map(
          prepareProgramCardRecommendedView
        );
      } catch {
        setNotification({
          message: 'Не удалось загрузить рекомендованные программы'
        });
      }
    }
  }
};
</script>

<style lang="scss">
.recommended-programs {
  .page-control-panel {
    margin-bottom: 24px;
  }
}
</style>

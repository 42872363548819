<template>
  <el-alert
    class="alert alert--attention"
    :closable="false"
  >
    {{ text }}
  </el-alert>
</template>

<script>
export default {
  name: "alertAttention",
  props: {
    text: {
      type: String
    }
  }
}
</script>

<style lang="scss">
 .el-alert.alert.alert--attention.el-alert--info.is-light{
   background-color: #F0F5FF;
   border-radius: 8px;
   padding: 16px 16px 16px 56px;
   margin-bottom: 24px;
   p{
     position: relative;
     font-weight: 400;
     font-size: 14px;
     line-height: 20px;
     color: #002D6E;
     margin: 0;
     &:before{
       content: '';
       position: absolute;
       top: calc(50% - 9px);
       left: -27px;
       display: block;
       width: 18px;
       height: 18px;
       background: url("../../assets/icons/icon-attention.svg") 100% 100% no-repeat;
     }
   }
 }

 @media (max-width: 480px) {
   .el-alert.alert.alert--attention.el-alert--info.is-light{
     padding: 16px 16px 16px 56px;
     p{
       font-size: 12px;
       line-height: 16px;
       &:before{
         left: -38px;
       }
     }
   }
 }

</style>

<template>
  <div
    v-if="items.length"
    class="container-list__program"
  >
    <program-list-component
      v-for="program of items"
      :key="program.pcId"
      :class="getComponentClass(program.pcStatus)"
      :program="program"
      :start-course-label="localeText.startCourse"
      :end-course-label="localeText.endCourse"
      :contact-manager-label="localeText.contactManager"
      :affiliated-with="localeText.affiliatedWith"
      :locale-course-status="localeCourseStatus"
    >
      <template #buttons>
        <link-l-m-s
          v-if="program.status.value === PROGRAM_STATUS.STUDIES && program.lms"
          :program="program"
        />
        <template v-if="!program.legal">
          <link-act v-if="program.pcStatus === PROGRAM_STATUS.SIGNING_ACT" />
          <link-agreement
            v-if="program.pcStatus === PROGRAM_STATUS.SIGNING_AGREEMENT"
          />
          <!--        <link-pay v-if="program.pcStatus === PROGRAM_STATUS.PAYMENT" />-->
          <link-graduation-doc
            v-if="
              program.pcStatus === PROGRAM_STATUS.COMPLETED && program.final_doc
            "
            :url="program.final_doc"
          />
          <link-review v-if="program.pcStatus === PROGRAM_STATUS.END" />

          <!--        <el-button
            v-if="showPaymentButton(program)"
            :disabled="!!paymentProcessProgramId"
            class="btn btn&#45;&#45;warning"
            icon="el-icon-postcard"
            @click="handlePayBtnClick(program)"
          >
            Оплатить
          </el-button>-->
          <el-button
            v-if="program.feedback"
            class="list-program__review-btn"
            icon=""
            @click="$emit('sendReview', program.program_id)"
          >
            <div class="flex-center">
              <div class="mr-2">
                <component
                  :is="iconLoader('present')"
                  class="icon nav__icon"
                />
              </div>
              <div>
                {{ localeText.myProgramsReview }}
              </div>
            </div>
          </el-button>
          <el-button
            v-if="isShowBtnCancelApp(program)"
            class="btn btn_danger"
            icon="el-icon-close"
            @click="onShowCancelApp(program.id)"
          >
            {{ localeText.withdrawAppBtn }}
          </el-button>
        </template>
      </template>
    </program-list-component>
    <el-dialog
      :title="localeText.withdrawAppBtn"
      :visible.sync="showCancelApp"
      class="dialog-cancel-app"
    >
      <div v-loading="isCancelLoad">
        <p class="dialog-cancel-app__info">
          {{ localeText.questionWithdrawApp }}:
        </p>
        <div
          v-if="selectedItem"
          class="dialog-cancel-app__content"
        >
          <p
            v-if="selectedItem.pcDirect"
            class="dialog-cancel-app__dir"
          >
            {{ selectedItem.pcDirect }}
          </p>
          <div
            v-if="selectedItem.program_full_name || selectedItem.program_name"
            class="dialog-cancel-app__name"
          >
            {{ selectedItem.program_full_name || selectedItem.program_name }}
          </div>
          <program-specialization
            v-if="selectedItem.pcType"
            :specialization="selectedItem.pcType"
            class="list-program__content-program-specialization"
          />
        </div>
        <div class="dialog-cancel-app__buttons">
          <el-button
            class="dialog-cancel-app__btn-cancel"
            @click="onHideModalCancelApp"
          >
            {{ localeText.cancelBtn }}
          </el-button>
          <el-button
            type="primary"
            @click="onCancelApp"
          >
            {{ localeText.yesRecallBtn }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import iconLoader from '@/utils/iconLoader';
import LinkAct from '../links/linkAct.vue';
import LinkAgreement from '../links/linkAgreement.vue';
import LinkGraduationDoc from '../links/linkGraduationDoc.vue';
import LinkLMS from '../links/linkLMS.vue';
import LinkPay from '../links/linkPay.vue';
import LinkReview from '../links/linkReview.vue';
import ProgramListComponent from './programListComponent.vue';
import ProgramSpecialization from './programSpecialization.vue';
import {PROGRAM_STATUS} from '@/utils/getProgramDataByStatus';
import Axios from '@/utils/Elk-Axios';
import {V1_APPLICATIONS} from '@/api';

export default {
  name: 'ProgramList',

  components: {
    LinkAct,
    LinkAgreement,
    LinkGraduationDoc,
    LinkLMS,
    LinkPay,
    LinkReview,
    ProgramListComponent,
    ProgramSpecialization
  },
  props: {
    localization: {
      type: Object,
      default: () => {}
    },
    programs: {
      type: Array,
      default: () => []
    },
    sendReviewId: {
      default: ''
    }
  },
  setup() {
    return {
      PROGRAM_STATUS
    };
  },

  data() {
    return {
      showCancelApp: false,
      selectedItemId: 0,
      items: this.programs ?? [],
      isCancelLoad: false,
      componentClass: {
        [PROGRAM_STATUS.COMPLETED]: 'end-program',
        [PROGRAM_STATUS.WAITING]: 'start-program'
      },
      idsCancel: [],
      paymentProcessProgramId: null,
      localeText: {
        cancelBtn: this.localization.commonCaption('cancelBtn'),
        yesRecallBtn: this.localization.widgetCaption('yesRecallBtn'),
        questionWithdrawApp: this.localization.widgetCaption(
          'questionWithdrawApp'
        ),
        withdrawAppBtn: this.localization.widgetCaption('withdrawAppBtn'),
        myProgramsReview: this.localization.widgetCaption('myProgramsReview'),
        startCourse: this.localization.widgetCaption('startCourse'),
        endCourse: this.localization.widgetCaption('endCourse'),
        affiliatedWith: this.localization.widgetCaption('affiliatedWith'),
        contactManager: this.localization.widgetCaption('contactManager')
      },
      localeCourseStatus: {
        courseCompleted: this.localization.widgetCaption('courseCompleted'),
        courseInProgress: this.localization.widgetCaption('courseInProgress'),
        courseStartsToday: this.localization.widgetCaption('courseStartsToday'),
        courseDaysLeft: this.localization.widgetCaption('courseDaysLeft')
      }
    };
  },
  computed: {
    selectedItem() {
      return this.items.find(e => e.id === this.selectedItemId);
    }
  },

  watch: {
    programs(v) {
      this.items = this.mapItems(v);
    },
    idsCancel() {
      this.items = this.mapItems(this.programs);
    },
    sendReviewId() {
      if (this.sendReviewId) {
        this.items = this.mapItems(
          this.programs.map(e => {
            return {
              ...e,
              feedback: e.program_id == this.sendReviewId ? false : e.feedback
            };
          })
        );
      }
    }
  },
  created() {
    this.$store.dispatch('services/fetchServiceManager');
  },

  methods: {
    iconLoader,
    getComponentClass(status) {
      if (Object.prototype.hasOwnProperty.call(this.componentClass, status)) {
        return this.componentClass[status];
      }

      return '';
    },
    isShowBtnCancelApp(program) {
      if (program.cxl_request) {
        return false;
      }

      return (
        program.status.value === this.PROGRAM_STATUS.PAYMENT.toLowerCase() ||
        program.status.value === this.PROGRAM_STATUS.PROCESSING.toLowerCase() ||
        program.status.value === this.PROGRAM_STATUS.WAITING.toLowerCase()
      );
    },
    showPaymentButton(program) {
      return (
        program.need_payment &&
        this.paymentProcessProgramId !== program.id &&
        program.can_pay
      );
    },
    /*handlePayBtnClick(program) {
      this.$emit('click-pay-btn', program);
    },*/
    mapItems(items) {
      return (
        items?.map(e => ({
          ...e,
          cxl_request: e.cxl_request || this.idsCancel.includes(e.id)
        })) ?? []
      );
    },
    async onCancelApp() {
      try {
        this.isCancelLoad = true;
        await Axios.delete(
          `${V1_APPLICATIONS}?application=${this.selectedItem.id}`
        );
        this.idsCancel.push(this.selectedItem.id);
        this.onHideModalCancelApp();

        const h = this.$createElement;
        this.$notify({
          title: 'Успех!',
          type: 'success',
          message: h('i', {}, 'Заявка отозвана')
        });
      } catch (e) {
        const message =
          e.response?.data?.message ?? 'Не удалось отозвать заявку';
        const h = this.$createElement;
        this.$notify({
          title: '',
          type: 'error',
          message: h('i', {}, message)
        });
      } finally {
        this.isCancelLoad = false;
      }
    },
    onHideModalCancelApp() {
      this.showCancelApp = false;
    },
    onShowCancelApp(id) {
      this.selectedItemId = id;
      this.showCancelApp = true;
    }
  }
};
</script>

<style lang="scss">
.container-list__program {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
  border-radius: 4px 4px 0 0;
  padding: 24px;
}
.container-list__program .btn.btn_danger {
  background-color: $system-error-dark;
}
.container-list__program .btn.btn_danger:hover {
  background-color: $system-error-1;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-cancel-app {
  .el-dialog {
    width: 520px;
    .el-dialog__title {
      font-size: 24px;
      font-weight: 600;
    }
  }

  &__info {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $color-base-black;
  }
  &__content {
    padding: 16px;
    border-radius: 4px;
    background-color: $color-background-gray-2;
    color: $color-base-black;
  }
  &__dir {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  &__name {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  &__buttons {
    display: flex;
    gap: 8px;
    margin-top: 24px;
  }

  .dialog-cancel-app__btn-cancel {
    background-color: $color-background-gray-2;
    color: $color-gray-dusk;
  }
  .dialog-cancel-app__btn-cancel:hover {
    background-color: $color-gray-morn-2;
    color: $color-gray-dusk;
  }
}

@media (max-width: 640px) {
  .dialog-cancel-app {
    .el-dialog {
      width: 100%;
    }

    &__buttons {
      flex-direction: column;
    }
    &__buttons .el-button + .el-button {
      margin: 0px;
    }
  }
}
</style>

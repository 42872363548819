<template>
  <a
    class="profile__error"
    :href="IT_SUPPORT"
    target="_blank"
  >
    <iconError />
    <span>{{ $t(getCommonCaptionByName('reportError')) }}</span>
  </a>
</template>

<script>
import iconError from '@/assets/icons/error.svg?inline';
import {IT_SUPPORT} from '@/router/routes';
import {getCommonCaptionByName} from '@/i18n/utils';

export default {
  name: 'ProfileError',
  components: {
    iconError
  },
  data() {
    return {
      IT_SUPPORT
    };
  },
  methods: {
    getCommonCaptionByName
  }
};
</script>

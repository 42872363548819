import '@/utils/checkLang.js';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/styles/index-tailwind.css';

/*
import { isProd } from '@/utils/constants';

if (isProd()) {
  import('@/sentry');
}
*/

import './assets/styles/ui/normalize.css';
import './assets/styles/ui/typography.css';
import './plugins/element.js';
import './plugins/vuelidate.js';
import './plugins/maskedInput.js';
import './assets/styles/main.scss';
import './plugins/vue-lazyload';
import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css';

import '@/plugins/globalComponents';

import globalFunc from './assets/js/global-fanc.js';
import CaptionNamesPlugin from './plugins/captionNamesPlugin';
import setNotificationPlugin from './plugins/setNotificationPlugin';
import VDraggable from 'vue-sortable-list';
import i18n from './i18n';

export const filterBus = new Vue();

Vue.use(CaptionNamesPlugin);
Vue.use(setNotificationPlugin);
Vue.use(VDraggable);
Vue.use(globalFunc);

Vue.config.productionTip = false;

window.addEventListener('resize', () => store.commit('resizeWindow'));

window.VueCalendarLang = function () {
  return {
    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    limit: 'Limit reached ({{limit}} items max).',
    loading: 'Загрузка...',
    minLength: 'Mин. длина',
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    notSelected: 'Ничего не выбрано',
    required: 'Требуемый',
    search: 'Поиск'
  };
};

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

import moment from "moment";

function sortDate(dateArr, valueKey, desc) {
  // console.log("SORT DATE", dateArr, moment("Дата не определена", "DD-MM-YYYY"))
  return dateArr.sort((a, b) => {
      const el1 = moment(b[valueKey], "DD-MM-YYYY");
      const el2 = moment(a[valueKey], "DD-MM-YYYY");
    if (el1 > el2) {
      return desc ? 1 : -1;
    }

    if (el1 < el2) {
      return desc ? -1 : 1;
    }

    return 0;
  });
}

function get_discount(old_price, price) {
  let res = 0
  if(old_price && price) {
    res = Number(Number(price) / Number(old_price) - 1) * 100;
  }
  return res;
}

export default function sortFilterDrop(arr, data) {

  switch (data) {
    case 'price':
      arr.sort((a, b) => Number(a.pcPrice) - Number(b.pcPrice));
      break;
    case 'price_desc':
      arr.sort((a, b) => Number(b.pcPrice) - Number(a.pcPrice));
      break;
    case 'date':
      arr = sortDate(arr, 'pcStart', false);
      break;
    case 'date_desc':
      arr = sortDate(arr, 'pcStart', true);
      break;
    case 'discount':
      arr.sort((a, b) => get_discount(b.pcPrice, b.pcOldPrice) - get_discount(a.pcPrice, a.pcOldPrice));
      break;
    default:
      arr.sort((a, b) => a.pcCaption.toLowerCase() > b.pcCaption.toLowerCase() ? 1 : -1);
  }

  return arr;
}



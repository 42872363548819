<template>
  <div class="filter-block">
    <div class="page-control-panel-mobil-addition">
      <search-form
        v-if="searchable"
        v-model="searchValue"
        :field-placeholder="searchPlaceholder"
        @clearField="clearSearch"
      />

      <button
        class="vi-btn vi-btn-filter"
        @click.prevent="show"
      >
        <filter-icon />
        {{ labels.filterMobileBtn }}
        <span v-show="selections.length > 0"> ({{ selections.length }})</span>
      </button>
    </div>

    <div
      class="great-filter"
      :class="{'great-filter--active': visible}"
    >
      <div class="great-filter__header">
        <button
          class="arrow-btn-back"
          @click.prevent="hide"
        >
          <arrow-left-icon fill="#0F0F14" />
          {{ labels.backBtn }}
        </button>
      </div>

      <div class="page-control-panel page-control-panel--four-col">
        <search-form
          v-if="searchable"
          v-model="searchValue"
          :field-placeholder="searchPlaceholder"
          @clearField="clearSearch"
        />
        <slot />
      </div>

      <div class="great-filter__footer">
        <button
          class="vi-btn vi-btn--hollow"
          @click.prevent="handleClearBtnClick"
        >
          {{ labels.clearMobileBtn }}
        </button>
        <button
          class="vi-btn"
          @click.prevent="handleProcessBtnClick"
        >
          {{ labels.doneMobileBtn }}
        </button>
      </div>
    </div>

    <selection-point-box :option="selections">
      <selection-point
        v-for="item in selections"
        :key="item.value + '_' + item.label"
        :sp-text="item.label"
        :select-name="item.value"
        @getText="unsetParam"
      />
    </selection-point-box>

    <button
      v-show="selections.length > 0"
      class="selectoin-poin-box-toggle-slide-btn"
      :class="{'selectoin-poin-box-toggle-slide-btn--collapse': showPointBox}"
      @click.prevent="toggleSelectionPointBox"
    >
      <span class="electoin-poin-box-toggle-slide-btn__caption-collapse"
        >свернуть</span
      >
      <span class="electoin-poin-box-toggle-slide-btn__caption-expand"
        >развернуть</span
      >
      <chevron-up-icon
        :width="8"
        :height="6"
        fill="#6B7A99"
      />
    </button>

    <div class="mb-2 flex flex-wrap">
      <filter-item
        v-for="(el, index) in filterTags"
        :key="index"
        :info="el"
      />
    </div>

    <div class="decor-line" />

    <div class="result-panel">
      <div class="result-panel-item">
        <div class="result-panel-item__key">{{ resultText }}:</div>
        <div class="result-panel-item__value">
          {{ Math.max(resultCount, 0) }}
        </div>
      </div>

      <div
        v-if="sortable"
        class="result-panel-item"
      >
        <div class="result-panel-item__key">{{ labels.sortLabel }}:</div>
        <div class="result-panel-item__value">
          <psevdoselect
            v-model="sortBy"
            :default-value="sortByInit"
            :ar-options="sortOptions"
            @input="onFilterChangePsevdo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectionPointBox from '@/components/selectionPoints/SelectionPointBox.vue';
import SelectionPoint from '@/components/selectionPoints/SelectionPoint.vue';
import FilterIcon from '@/components/icons/FilterIcon.vue';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import ChevronUpIcon from '@/components/icons/ChevronUpIcon.vue';
import SearchForm from '@/components/forms/SearchForm.vue';
import Psevdoselect from '@/components/formElements/Psevdoselect.vue';
import {filterBus} from '@/main';
import FilterItem from '@/components/filters/FilterItem.vue';
import {debounce} from 'throttle-debounce';
import {getCommonCaptionByName} from '@/i18n/utils';

export default {
  name: 'FilterBlock',
  components: {
    FilterItem,
    Psevdoselect,
    SearchForm,
    ChevronUpIcon,
    ArrowLeftIcon,
    FilterIcon,
    SelectionPointBox,
    SelectionPoint
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    loadMethod: {
      type: Function,
      required: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: 'Поиск...'
    },
    searchInitValue: {
      type: String,
      default: null
    },
    searchParam: {
      type: String,
      default: 'title'
    },
    resultText: {
      type: String,
      default: 'Найдено записей'
    },
    resultCount: {
      type: Number,
      default: 0
    },
    sortable: {
      type: Boolean,
      default: false
    },
    sortByInit: {
      type: Object,
      default: null
    },
    sortOptions: {
      type: Array,
      default: () => [
        {
          label: 'По названию',
          value: 'title'
        }
      ]
    }
  },
  data() {
    return {
      visible: false,
      showPointBox: false,
      selections: [],
      searchValue: this.searchInitValue,
      sortBy: this.sortByInit,
      filterTags: []
    };
  },
  computed: {
    labels() {
      return {
        sortLabel: this.$t(this.getCommonCaptionByName('sortBy')),
        filterMobileBtn: this.$t(this.getCommonCaptionByName('filterLabel')),
        doneMobileBtn: this.$t(this.getCommonCaptionByName('doneBtn')),
        clearMobileBtn: this.$t(this.getCommonCaptionByName('clear')),
        backBtn: this.$t(this.getCommonCaptionByName('back'))
      };
    }
  },
  watch: {
    searchValue() {
      this.debounceLoadData();
    }
  },
  mounted() {
    this.$slots.default.map(component => {
      component.componentInstance.$on('input', () => this.onFilterChange());
    });
    filterBus.$on(['dateRange', 'removeFilter', 'changePage'], v => {
      this.onFilterChange(v);
    });
    this.loadData();
  },
  methods: {
    getCommonCaptionByName,
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    flatten(arrays) {
      return arrays.reduce(
        (acc, val) => acc.concat(Array.isArray(val) ? this.flatten(val) : val),
        []
      );
    },
    async loadData(params) {
      const response = await this.loadMethod(this.buildParams(params));
      this.$emit('loaded', response);
    },
    debounceLoadData: debounce(500, async function () {
      await this.loadData();
    }),
    buildParams(externalParams) {
      let params = {};
      let labels = [];

      this.$slots.default.map(({componentInstance: {toParam, toLabel}}) => {
        toLabel() ? labels.push(toLabel()) : '';
        params = Object.assign(params, toParam());
      });

      this.filterTags = this.flatten(labels);

      if (this.searchable && this.searchValue) {
        params[this.searchParam] = this.searchValue;
      }

      if (this.sortable && this.sortBy?.length) {
        if (this.sortBy[0].endsWith('_desc')) {
          this.sortBy[0] = this.sortBy[0].replace('_desc', '');
          params.sort_desc = '1';
        }
        params.sort_by = this.sortBy[0];
      }

      if (externalParams && typeof externalParams === 'object') {
        Object.assign(params, externalParams);
      }
      return params;
    },
    onFilterChange(params) {
      this.loadData(params);
      filterBus.$emit('default');
    },
    // Сортировать по ...
    onFilterChangePsevdo() {
      filterBus.$emit('change', this.sortBy[0]);
    },
    fillSelections() {
      this.selections = [];

      this.$slots.default.map(({componentInstance: {getValue}}) => {
        if (getValue) {
          const value = getValue();
          if (value) {
            if (value instanceof Array) {
              this.selections = this.selections.concat(value);
            } else if (value instanceof Object) {
              this.selections.push(value);
            }
          }
        }
      });
    },
    unsetParam(label, value) {
      this.selections = this.selections.filter(
        selection => selection.label !== label && selection.value !== value
      );
      this.$slots.default.map(({componentInstance}) => {
        componentInstance.selected =
          componentInstance
            .getValue()
            ?.filter(
              selection =>
                selection.label !== label && selection.value !== value
            ) || [];
      });

      this.loadData();
    },
    handleClearBtnClick(event) {
      this.selections = [];
      this.$slots.default.map(({componentInstance}) => {
        componentInstance.selected = [];
      });

      this.$emit('clear', event);
      this.loadData();
    },
    handleProcessBtnClick(event) {
      this.fillSelections();
      this.$emit('process', event);
      this.loadData();
    },
    toggleSelectionPointBox() {
      this.showPointBox = !this.showPointBox;

      let selectPointBox = document.querySelector('.selection-points-box');
      let minHeight = selectPointBox.firstElementChild.offsetHeight;
      let selectPointBoxOldHeight = selectPointBox.offsetHeight;

      /*
        цифру 5 добавляю в условии, т.к. в методе processFilter задается высота контейнера selection-points-box
        которая ровна высоте первого дочернего элемента (первый параметр фильтра) + 5px.
      */
      if (selectPointBoxOldHeight === minHeight + 5) {
        //если свернут
        selectPointBox.style.height = 'auto';

        let selectPointBoxHeight = selectPointBox.offsetHeight;

        selectPointBox.style.height = selectPointBoxOldHeight + 'px';

        setTimeout(() => {
          selectPointBox.style.height = selectPointBoxHeight + 'px';

          setTimeout(() => {
            selectPointBox.style.height = 'auto';
          }, 200);
        }, 30);
      } else {
        //если развернут
        selectPointBox.style.height = selectPointBox.offsetHeight + 'px';
        setTimeout(() => {
          selectPointBox.style.height =
            selectPointBox.firstElementChild.offsetHeight + 5 + 'px';
        }, 100);
      }
    },
    clearSearch() {
      this.searchValue = null;
      this.loadData();
    }
  }
};
</script>

<script>
import { mapState } from 'vuex';
import MultipleSelectFilter from "@/components/filters/MultipleSelectFilter.vue";

export default {
  extends: MultipleSelectFilter,
  name: 'ForWhom',
  props: {
    label: {
      default: 'Для кого'
    },
    param: {
      type: String,
      default: 'for_whom'
    }
  },
  computed: {
    ...mapState({
      types: state => state.refForWhom.items.map(type => {
        return {
          value: type.code,
          label: type.name
        }
      }),
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('refForWhom/fetch').then(() => this.optionList = this.types)
    }
  }
}
</script>

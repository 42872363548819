<template>
  <div :class="{ body: true, active: menuActive }">
    <menu-wrapper :pageName="name" />
    <div class="page-wrapper">
      <main class="content" :class="pageClass">
        <div class="container">
          <div class="auth-component__section">
<!--            <registration-start />-->
            <component
              :is="loader(widget.signature)"
              v-for="widget in widgets"
              :key="widget.signature"
              :widget-data="widget"
            />
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import loader from '@/utils/widgetLoader.js';
import registrationStart from "@/components/authComponent/registrationStart";
import menuWrapper from "@/components/menuWrapper";
import PageFooter from "@/components/PageFooter";
import { mapState, mapGetters } from 'vuex';
export default {
  name: "registrationPage",
  components: {
    PageFooter,
    registrationStart,
    menuWrapper
  },
  props: {
    widgets: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      name: 'Регистрация',
      pageClass: 'registration-page-wrapper'
    }
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
  },
  methods: {
    loader
  }
}
</script>

<style lang="scss">
.content.registration-page-wrapper {
  padding-top: 80px;
}
</style>

<template>
  <div class="profile__info">
    <profile-avatar
      class="profile__avatar"
      :user-data="userData"
      :is-load-avatar="isLoadAvatar"
      :signature="signature"
      @update-user-avatar="setNewAvatar"
      @show-qr="showQR"
    />
    <div class="profile__name">
      <p>{{ userfullName }}</p>
      <profile-error v-if="!isLaptop" />
      <el-button
        v-if="isLaptop"
        class="btn"
        @click="showQR"
      >
        {{ localization.widgetCaption('AddContact') }}
      </el-button>
    </div>

    <div class="profile__table profile__table--contacts">
      <born-date
        :birth-date="userData.birth_date"
        :show-type="userData.birth_date_show_type_id"
        :is-adm="userData.is_adm"
        class="profile__table-cell"
        @update-info="updateInfo"
      />
      <div
        v-if="userData.is_adm"
        class="profile__table-cell profile__phone profile__phone--inner"
      >
        <p class="profile__table-title">
          {{ localization.commonCaption('contacts_internalPhone') }}
        </p>
        <p class="profile__table-info">
          <template v-if="isInternalAndWorkingPhonesLoading">
            {{ localization.commonCaption('loading') }}
          </template>
          <template v-else>
            {{ internalPhone || '-' }}
          </template>
        </p>
      </div>

      <div class="profile__table-cell profile__phone profile__phone--work">
        <p class="profile__table-title">
          {{ localization.commonCaption('contacts_officePhoneNumber') }}
        </p>
        <p class="profile__table-info">
          <a
            v-if="phone_pep"
            :href="`tel:${phone_pep}`"
            class="link"
          >
            {{ phone_pep }}
          </a>
          <span v-else> - </span>
        </p>
      </div>

      <personalPhone
        :personal-phone="userData.phone_mobile || ''"
        :show-phone="userData.is_phone_mobile_show"
        :is-adm="userData.is_adm"
        class="profile__table-cell profile__phone profile__phone--personal"
        @update-mobile-show="updateMobileShow"
        @update-info="updateInfo"
      />
      <div class="profile__table-cell profile__email profile__email--work">
        <p class="profile__table-title">
          {{
            userData.is_adm
            ? localization.commonCaption('contacts_officeEmail')
            : localization.commonCaption('contacts_email')
          }}
        </p>
        <p class="profile__table-info">
          <a
            v-if="userData.email"
            :href="`mailto:${userData.email}`"
            class="link"
          >
            {{ userData.email }}
          </a>
          <span v-else>
            {{ localization.commonCaption('isNotIndicated') }}
          </span>
        </p>
      </div>

      <gender-block
        class="profile__table-cell profile__email profile__email--personal"
        :gender="userData.gender"
        @set-gender="updateGender"
      />

      <personal-email
        :personal-email="userData.email_personal"
        :show-email="userData.is_email_personal_show"
        :is-adm="userData.is_adm"
        class="profile__table-cell"
        @update-info="updateInfo"
        @update-email-show="updateEmailShow"
      />
      <register-address-block
        v-if="userDpo"
        class="profile__table-cell"
      />
      <category-block
        v-if="userDpo"
        class="profile__table-cell profile__table-cell--listener-category"
        :category="userData.category_listener"
      />
      <nationality-block
        class="profile__table-cell"
        :nationality="userData.nationality"
        @set-nationality="updateNationality"
      />

      <residential-address-block
        v-if="userDpo"
        class="profile__table-cell"
      />
      <div
        v-if="userData.is_adm"
        class="profile__table-cell controls"
      >
        <p class="profile__table-title">
          {{ localization.commonCaption('ЛКНаПортале') }}
        </p>
        <p class="profile__table-info">
          <a
            :href="USER_PORTAL_LINK"
            target="_blank"
            class="link"
          >
            www.hse.ru/user
          </a>
        </p>
      </div>
    </div>

    <div
      v-if="userData.medical_checkup_date"
      class="profile__table-cell profile__phone profile__phone--inner"
    >
      <p class="profile__table-title">
        {{ localization.commonCaption('medicalCheckupDate') }}
      </p>
      <p class="profile__table-info">
        {{ $d(new Date(userData.medical_checkup_date), 'news') }}
      </p>
    </div>

    <div
      v-if="userData.is_adm"
      class="profile__links"
    >
      <a
        :href="CHANGE_PERSONAL_DATA_LINK"
        class="link"
      >
        {{ localization.widgetCaption('changePersonalData') }}
      </a>
    </div>

    <el-dialog
      class="business-card__dialog"
      :visible.sync="showQRDialog"
      :top="isMobile ? '0' : '12px'"
      :modal-append-to-body="false"
      :close-on-click-modal="true"
      :width="dialogWidth"
      center
      @open="qrLoading = true"
      @opened="openDialog"
    >
      <ElkLoading v-if="qrLoading" />

      <template v-else>
        <img
          class="business-card__image"
          :src="businessCardUrl"
          alt="визитка"
        />

        <el-button
          class="btn"
          @click="dowloadBusinessCard"
        >
          {{ localization.widgetCaption('DownloadCard') }}
        </el-button>
      </template>
    </el-dialog>

    <p class="u-hide h4--hse">buinsess card</p>
    <business-card
      v-show="false"
      ref="business-card"
      :user-data="userData"
      :main-address="mainAddress"
      :main-postion-name="mainPostionName"
    />
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import html2canvas from 'html2canvas';
import QRCode from 'qrcode';

import {getInternalAndWorkingPhone} from './api';
import generateVCard from '@/utils/generateVCard.js';
import ElkLoading from '@/components/common/elkLoading';
import personalEmail from './personalEmail.vue';
import personalPhone from './personalPhone';
import nationalityBlock from './nationality/index';
import genderBlock from './gender/index';
import categoryBlock from './categoryListener/index';
import registerAddressBlock from './registerAddress/index';
import residentialAddressBlock from './residentialAddress/index';
import bornDate from './bornDate';
import ProfileError from '../profileError';
import ProfileAvatar from '../profileAvatar';
import BusinessCard from './BusinessCard.vue';
import addPlusForRusPhone from '@/utils/addPlusForRusPhone.js';

const USER_PORTAL_LINK = 'https://www.hse.ru/user/index.html#tab-main';
const CHANGE_PERSONAL_DATA_LINK =
  'https://bpm.hse.ru/Runtime/Runtime/Form/SA__MyInfo';

export default {
  name: 'ProfileInfo',

  components: {
    ProfileAvatar,
    ProfileError,
    genderBlock,
    categoryBlock,
    registerAddressBlock,
    residentialAddressBlock,
    personalEmail,
    personalPhone,
    bornDate,
    nationalityBlock,
    ElkLoading,
    BusinessCard
  },

  inject: ['signature', 'localization'],
  props: ['userData', 'isLoadAvatar'],

  data() {
    return {
      USER_PORTAL_LINK,
      CHANGE_PERSONAL_DATA_LINK,
      isInternalAndWorkingPhonesLoading: false,
      internalPhone: null,
      workingPhone: null,
      mainAddress: null,
      mainPostionName: null,
      showQRDialog: false,
      qrLoading: false,
      qrGenerated: false,
      businessCardUrl: null
    };
  },

  computed: {
    ...mapGetters(['isMobile', 'userDpo']),
    ...mapState({
      width: state => state.width,
      phone_pep: state => state.user.phone_pep
    }),

    dialogWidth() {
      return this.isMobile ? '100%' : '370px';
    },

    isLaptop() {
      return this.width < 1024;
    },

    userfullName() {
      return `${this.userData.last_name} ${this.userData.first_name} ${this.userData.middle_name}`;
    },

    vcfText() {
      const data = {...this.userData};
      data.phone_mobile = addPlusForRusPhone(data.phone_mobile);
      return generateVCard(data, this.mainAddress);
    },

    defaultQRCodeWidth() {
      return window.innerWidth - window.innerHeight > 0
        ? window.innerHeight - 75
        : window.innerWidth - 75;
    }
  },

  created() {
    if (this.userData.is_adm) this.getWorkAddress();
  },

  methods: {
    updateInfo(v) {
      this.$emit('update-info', v);
    },
    updateMobileShow(v) {
      this.$emit('update-mobile-show', v);
    },
    updateEmailShow(v) {
      this.$emit('update-email-show', v);
    },
    updateNationality(nationality) {
      this.$emit('update-info', {type: 'nationality', value: nationality});
    },
    updateGender(gender) {
      this.$emit('update-info', {type: 'gender', value: gender});
    },
    /* updateCategory(value) {
       //this.$emit('update-info', { type: 'category_listener', value });
     },*/
    setNewAvatar(avatar) {
      this.$emit('set-new-avatar', avatar);
    },
    async getWorkAddress() {
      this.isInternalAndWorkingPhonesLoading = true;
      try {
        const {address, positions} = await getInternalAndWorkingPhone();
        const addressesObject = address.find(({is_main}) => is_main);
        const mainPosition = positions.find(({is_main}) => is_main);

        this.mainAddress = addressesObject;
        this.internalPhone = addressesObject.phone_internal;
        this.workingPhone = addressesObject.phone_work;
        this.mainPostionName = mainPosition?.position_name;
      } catch (e) {
        this.mainAddress = null;
        this.internalPhone = null;
        this.workingPhone = null;
        this.mainPostionName = null;
      } finally {
        this.isInternalAndWorkingPhonesLoading = false;
      }
    },

    generateQRCode(width, element) {
      const currentWidth = width ? width : this.defaultQRCodeWidth;
      const currentElement = element ? element : this.$refs['qrcode'];
      const config = {
        width: currentWidth,
        margin: 0,
        color: {dark: '#002D6E'},
        errorCorrectionLevel: 'L'
      };

      QRCode.toCanvas(currentElement, this.vcfText, config);

      this.qrGenerated = true;

      // this.qrLoading = false;
    },

    showQR() {
      this.showQRDialog = !this.showQRDialog;
    },

    async openDialog() {
      if (!this.qrGenerated) {
        this.generateQRCode(
          550,
          this.$refs['business-card'].$refs['business-card-qrcode']
        );

        const canvas = await html2canvas(this.$refs['business-card'].$el, {
          onclone(clonedDoc) {
            clonedDoc.getElementById('business-card').style.display = 'block';
          }
        });

        canvas.toBlob(blob => {
          this.businessCardUrl = URL.createObjectURL(blob);
        }, 'image/png');
      }

      this.qrLoading = false;
    },

    async dowloadBusinessCard() {
      const link = document.createElement('a');

      link.href = this.businessCardUrl;

      link.download = 'business_card.png';

      link.click();
    }
  }
};
</script>

<!-- add style 31.03.23 Farit -->
<style lang="scss">
.profile_el_empty {
  border-radius: 5px;
  border: 1px solid rgb(255, 86, 78);
  padding: 3px 5px 3px 20px;
}
.profile_padding_off {
  padding: unset;
}
article.profile {
  padding: 0;
}

.profile__info {
  .profile__name {
    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #0f0f14;
    }
  }
}

.input-profile-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile__table.profile__table--contacts {
  padding-top: 24px;
  row-gap: 0;

  .profile__table-cell {
    padding: 0 20px 20px 0;

    .profile__table-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .input-profile-contact__value {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #0f0f14;
    }

    .link {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    .el-select.profile__contacts-select {
      max-width: 100%;
      margin: 0;
      padding: 0;
      flex-shrink: 0;
      flex-grow: 1;
      height: 40px;

      .el-input.el-input--suffix {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        height: 40px;

        input {
          max-width: 150px;
          height: 40px;
        }
      }
    }

    .profile__contacts-date {
      margin: 0;
      padding: 0;
    }
  }

  .profile__table-cell:nth-child(3n + 3) {
    // border-right: unset !important;
    padding-right: 0;
  }

  //.profile__table-cell:last-child {
  //  padding-right: 0;
  //}

  .profile__table-cell.profile__phone.profile__phone--personal {
    padding-right: 20px;

    .form.form--profile-contact.form.form--profile-contact {
      // Поставил margin-top: 0; так как ломает верстку
      .form__item {
        margin-top: 0;
      }

      // Поставил margin-top: 0; так как ломает верстку
    }
  }

  .profile__table-cell.profile__email.profile__email--personal {
    padding-right: 0;
  }

  .profile__contacts-container {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0;
  }

  .profile__born.profile__table-cell {
    .btn.btn--secondary.btn--icon {
      width: 38px;
      height: 40px;
      order: 1;
      margin: 0 0 0 16px;
    }
  }
}

@media (max-width: 1400px) {
  .profile__table.profile__table--contacts {
    .profile__table-cell {
      .link {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .profile__table-cell {
      .el-select.profile__contacts-select {
        .el-input.el-input--suffix {
          input {
            max-width: 130px;
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .profile__table.profile__table--contacts {
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: unset;

    .profile__born {
      grid-area: auto;
    }

    .profile__table-cell.profile__phone.profile__phone--personal {
      margin-right: 0;
      padding-right: 0;
    }

    .profile__phone.profile__phone--inner {
      border-right: unset;
    }

    .profile__table-cell:nth-child(2n + 2),
    .profile__table-cell.controls {
      padding-right: 0;
      border-right: unset;
    }

    .profile__table-cell:last-child {
      border-right: 1px solid #eeeff4;
    }
  }
}

@media (max-width: 1023px) {
  article.profile {
    .profile__info {
      row-gap: 8px;
      -moz-column-gap: 32px;
      -webkit-column-gap: 32px;
      column-gap: 32px;
      -ms-grid-columns: 120px 32px 1fr;
      grid-template-columns: 120px 1fr;
      -ms-grid-rows: minmax(44px, auto) 8px auto;
      grid-template-rows: minmax(44px, auto) auto;
      grid-template-areas:
        'avatar name'
        'avatar contacts'
        'avatar links';

      .profile__table.profile__table--contacts {
        display: flex;
        flex-direction: column;
        border-top: unset;
      }

      .no-overflow.profile__avatar {
        .el-image.avatar {
          width: 120px !important;
          height: 120px !important;

          .avatar__dummy {
            width: inherit !important;
            height: 120px !important;
          }
        }
      }

      .no-overflow {
        .btn {
          padding: 8px 14px;

          span {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    .profile__name {
      display: grid;
      -moz-column-gap: 20px;
      column-gap: 20px;
      padding-bottom: 19px;
      grid-template-columns: 1fr auto;
      grid-template-areas: 'fio error';
      border-bottom: 1px solid #eeeff4;

      .el-button.btn.el-button--default {
        padding: 8px 14px;

        span {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  .profile__table.profile__table--contacts {
    .profile__table-cell {
      padding: 0 0 20px 0;
      border-right: unset !important;

      &.profile__phone.profile__phone--personal {
        border-bottom: 1px solid #eeeff4;
        margin-bottom: 20px;
      }
    }
  }

  .profile__links {
    .link {
      width: 100%;
      cursor: pointer;
      text-align: center;
      border-radius: 4px;
      padding: 10px 16px;
      display: inline-block;
      text-decoration: none;
      vertical-align: middle;
      color: #ffffff;
      background-color: #0050cf;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.43;
      -webkit-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
      white-space: normal;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      &:hover {
        text-decoration: none;
        background-color: #002d6e;
      }
    }
  }
}

@media (max-width: 768px) {
  article.profile {
    .profile__info {
      // row-gap: 0;
      // column-gap: 0;
      // grid-template-columns: 1fr;
      // grid-template-rows: unset;
      // grid-template-areas:
      //   "avatar name"
      //   "contacts contacts"
      //   "links links";
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .no-overflow.profile__avatar {
        width: 130px;
        padding-bottom: 0;

        .el-image.avatar {
          width: 100% !important;
          height: auto !important;

          img {
            display: block;
            width: 100% !important;
            height: auto !important;
          }
        }
      }

      .profile__name {
        flex-grow: 1;

        border: unset;
        padding-bottom: 20px;
        display: flex;
        gap: 5px;
        flex-direction: column;

        .el-button.btn.el-button--default {
          height: 40px;
        }
      }

      .profile__table.profile__table--contacts {
        width: 100%;
        border-top: 1px solid #eeeff4;
      }
    }

    .profile__error {
      justify-content: flex-start;
    }
  }
}

@media (max-width: 520px) {
  article.profile {
    .profile__info {
      display: flex;
      flex-direction: column;
    }
  }

  article.profile .profile__info .no-overflow.profile__avatar {
    width: 100%;
  }

  article.profile
    .profile__info
    .no-overflow.profile__avatar
    .el-image.avatar
    img {
    max-width: 200px;
    margin: 0 auto;
  }
}
</style>

<template>
  <div
    id="wrapper-form-review"
    class="wrapper-form-review"
    :class="{'wrapper-form-review__dialog': isDialog}"
  >
    <el-form
      ref="form"
      :model="form"
    >
      <h1>
        {{ mainTitle }}
      </h1>
      <div class="subtitle-review">
        Инструменты повышения эффективности бизнес-процессов промышленных предприятий
      </div>
      <p>
        Посмотрите на автора и название книги.
        Автор вам знаком? Что вы о нем знаете? Упомяните важное.
        А что с названием книги? Оно интересное? Может быть,
        создает настроение или намекает, что ждет в книге? Скажите
        об этом несколько слов.
      </p>
      <p>Сколько баллов вы поставите этой программе?</p>

      <el-form-item class="item__level-points">
        <el-slider
          v-model="value"
          :step="1"
          show-stops
          :max="5"
          :marks="marks"
          :show-tooltip="false"
        >
        </el-slider>
      </el-form-item>

      <el-form-item label="Текст отзыва">
        <el-input
          v-model="textarea"
          type="textarea"
          :rows="6"
          placeholder="Начните писать текст.."
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <div class="inputCheckbox">
          <input
            id="checkbox1"
            class="custom-checkbox"
            type="checkbox"
            name="checkbox1"
            checked
          />
          <label for="checkbox1">
            Разрешаю разместить текст моего отзыва на ресурсах НИУ ВШЭ
          </label>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="sendReview"
          class="btn btn-review-link"
        >
          Отправить отзыв
        </el-button>
        <router-link
          class="btn btn__el-form-back"
          to="programsPage"
        >
          Вернуться назад
        </router-link>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import { SEND_REVIEW } from "@/api";

export default {
  name: "newReviewForm",
  props: ['isDialog', 'idProgram'],
  data() {
    return {
      form: {
        name: ''
      },
      mainTitle: 'Оставить отзыв',
      textarea: '',
      value: 0,
      marks: {
        0: '0',
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        // 6: '6',
        // 7: '7',
        // 8: '8',
        // 9: '9',
        // 10: '10',
      },
      // fullscreenLoading: false
    }
  },
  methods: {
    // openFullScreen1() {
    //   this.fullscreenLoading = true;
    //   setTimeout(() => {
    //     this.fullscreenLoading = false;
    //   }, 1500);
    // },
    getPayload() {
      const fd = new FormData();
      fd.append('comment', this.textarea ?? '');
      fd.append('program_id', this.idProgram);
      fd.append('score', this.value);

      return fd;
    },
    async sendReview() {
      const h = this.$createElement;
      try {
        await Axios.post(SEND_REVIEW, this.getPayload());
        this.$emit('success');
        this.$notify({
          title: 'Сообщение',
          type: 'success',
          message: h('i', { }, 'Отзыв отправлен')
        });
      } catch {
        // ну удалось отправить отзыв
        this.$notify({
          title: 'Сообщение',
          type: 'error',
          message: h('i', { }, 'Не удалось отправить отзыв')
        });
      }
    }
  }
}
</script>

<style lang="scss">
  .wrapper-form-review{
    display: flex;
    align-items: center;
    justify-content: center;
    .el-form{
      .btn.btn-review-link{
        height: 48px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn.btn__el-form-back{
        height: 48px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      .subtitle-review{
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: var(--font-color);
        margin-bottom: 24px;
      }
      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: var(--font-color-alt);
        margin-bottom: 24px;
      }
      .el-form-item__label{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--font-color-alt);
        margin-bottom: 8px;
      }
      .el-textarea{
        textarea{
          border: 1px solid #D3D8E6;
          padding: 12px;
          &::placeholder{
            font-family: 'HSE Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: var(--font-color-alt);
          }
        }
      }
      .el-form-item{
        margin-bottom: 24px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .item__level-points{
        margin-bottom: 40px;
        .el-slider__runway{
          height: 8px;
          border-radius: 6px;
          .el-slider__button{
            width: 24px;
            height: 24px;
            background-color: var(--dark-blue);
            box-sizing: border-box;
            border: unset;
          }
          .el-slider__bar{
            background-color: #C2DAFF;
            height: 8px;
            border-radius: 6px;
          }
          .el-slider__stop{
            width: 0;
            height: 8px;
            background-color: #F5F6FA;
          }
          .el-slider__marks{
            .el-slider__marks-text{
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;
              color: var(--font-color-alt);
              margin-top: 27px;
              &:before{
                content: '';
                width: 1px;
                height: 13px;
                background-color: #D3D8E6;
                display: block;
                position: absolute;
                top: -20px;
                left: calc(50% - 1px);


              }
            }
          }

        }
      }

    }
  }

  .wrapper-form-review__dialog {
    .el-slider__marks-text {
      white-space: nowrap;
    }
  }

  @media (max-width: 1440px) {
    .wrapper-form-review{
      .el-form{
        width: 778px;
      }
    }
    .wrapper-form-review__dialog {
      .el-form{
        width: 100%;
        padding: 20px 30px;
      }
    }
    .review-dialog .el-dialog{
      width: 80%;
    }
  }

  @media (max-width: 1024px) {
    .wrapper-form-review{
      .el-form{
        width: 641px;
      }
    }
    .wrapper-form-review__dialog {
      .el-form{
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
   .wrapper-form-review{
     .el-form{
       width: 530px;
       padding: 30px 49px;
     }
   }
    .wrapper-form-review__dialog {
      .el-form{
        width: 100%;
        padding: 20px;
      }
    }
    .review-dialog .el-dialog{
      width: 100%;
    }
  }
  @media (max-width: 530px) {
    .wrapper-form-review{
      .el-form{
        width: 480px;
        padding: 30px 49px;
      }
    }
    .wrapper-form-review__dialog {
      .el-form{
        width: 100%;
      }
    }
  }

  @media (max-width: 480px) {
    .wrapper-form-review{
      .el-form{
        width: 100%;
        padding: 24px;
      }
    }
    .wrapper-form-review__dialog {
      .el-form{
        width: 100%;
      }
    }
  }

</style>

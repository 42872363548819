<template>
  <div>
    <p class="profile__table-title">
      {{
        $t(
          getCommonCaptionByName(
            `${isAdm ? 'contacts_personalEmail' : 'contacts_additionalEmail'}`
          )
        )
      }}
    </p>
    <div v-show="!edit" class="profile__table-info">

      <div class="input-profile-contact">
        <div class="input-profile-contact__value">
          <a
            :href="`mailto:${personalEmail}`"
            class="link"
            v-if="personalEmail"
            >{{ personalEmail }}</a
          >
          <span v-else>
            {{ $t(getCommonCaptionByName('isNotIndicated')) }}
          </span>
        </div>
        <div class="input-profile-contact__btn">
          <btn-show
            :btnVisibility="btnVisibility"
            :iconVisibility="showEmail"
            :titleBtn="titleBtn"
            :loaderBtn="loader"
            @edit-show="editShow"
          />
          <button @click="startEdit" class="btn btn--secondary btn--icon">
            <edit class="edit-icon" />
          </button>
        </div>
      </div>
    </div>
    <form
      v-show="edit"
      @submit.prevent="editEmail"
      class="input-profile-contact"
    >
      <div class="input-profile-contact__value">
        <input
          ref="input"
          v-model="email"
          type="email"
          name="new-email"
          id="new-email"
          placeholder="example@hse.ru"
          @keyup="closeEdit"
          pattern="([A-z0-9_.-]{1,})@([A-z0-9_.-]{1,}).([A-z]{2,8})"
          class="input input--new-value"
        />
      </div>
      <div class="input-profile-contact__btn">
        <button type="submit" class="btn btn--secondary btn--icon">
          <Checkmark class="edit-icon" />
        </button>
      </div>
      <div class="input-profile-contact__btn">
        <button @click.prevent="closeEdit" class="btn btn--secondary">
          {{ $t(getCommonCaptionByName('cancel')) }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import Edit from '@/assets/icons/edit.svg?inline';
import Checkmark from '@/assets/icons/check-mark.svg?inline';
import setNotification from '@/utils/setNotification';
import btnShow from '../btnShow';
import { SET_EMAIL_PERSONAL, SET_EMAIL_VISIBLE } from '@/api';
import { getUiItemCaption, getCommonCaptionByName } from '@/i18n/utils';

export default {
  name: 'personal-email',
  inject: ['signature'],
  components: {
    Edit,
    Checkmark,
    btnShow,
  },
  props: {
    personalEmail: String,
    showEmail: Boolean,
    isAdm: Boolean,
  },
  data() {
    return {
      edit: false,
      email: this.personalEmail,
      loader: false,
      titleBtnOff: this.$t(
        this.getUiItemCaption(
          this.signature,
          'turnOffVisibilityOfPersonalEmail'
        )
      ),
      titleBtnOn: this.$t(
        this.getUiItemCaption(this.signature, 'turnOnVisibilityOfPersonalEmail')
      ),
    };
  },
  computed: {
    btnVisibility: function () {
      return !!this.email;
    },
    titleBtn() {
      const { showEmail, titleBtnOn, titleBtnOff } = this;
      return showEmail ? titleBtnOff : titleBtnOn;
    },
  },
  methods: {
    getUiItemCaption,
    getCommonCaptionByName,
    setNotification,
    startEdit() {
      this.edit = true;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 0);
    },
    closeEdit(e) {
      if (e.keyCode == 27 || e.type == 'click') {
        this.email = this.personalEmail;
        this.edit = false;
      }
    },
    async editEmail() {
      try {
        if (this.email === '') {
          await Axios.post(SET_EMAIL_VISIBLE, {
            is_email_personal_show: false,
          });
          this.$emit('update-email-show', { value: false });
        }
        let res = await Axios.post(SET_EMAIL_PERSONAL, {
          email_personal: this.email,
        });
        if (res.status === 200) {
          this.$emit('update-info', { type: 'email', value: this.email });
          this.edit = false;
        }
        setNotification({
          message: `Информация сохранена`,
          type: 'success',
        });
      } catch (err) {
        if (!err.isHandled) {
          this.setNotification({
            message: this.$t(
              getUiItemCaption(this.signature, 'failSettingNewEmail')
            ),
          });
        }
      }
    },
    async editShow() {
      this.loader = true;
      try {
        const value = { is_email_personal_show: !this.showEmail };
        let res = await Axios.post(SET_EMAIL_VISIBLE, value);
        if (res.status === 200) {
          this.$emit('update-email-show', { value: !this.showEmail });
          this.iconVisibility = !this.iconVisibility;
        }
      } catch (err) {
        if (!err.isHandled) {
          this.setNotification({
            message: this.$t(
              getUiItemCaption(this.signature, 'failSettingVisibialityOfEmail')
            ),
          });
        }
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
<style lang="scss">
// некоторые стили тут: /var/www/frontend/elk_frontend/src/assets/styles/components/profile.scss
</style>

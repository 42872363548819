import { render, staticRenderFns } from "./PeriodRange.vue?vue&type=template&id=ffd342ba&scoped=true"
import script from "./PeriodRange.vue?vue&type=script&lang=js"
export * from "./PeriodRange.vue?vue&type=script&lang=js"
import style0 from "./PeriodRange.vue?vue&type=style&index=0&id=ffd342ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffd342ba",
  null
  
)

export default component.exports
export default {
  namespaced: true,
  state: {
    localhost: 'localhost',
  },
  getters: {
    isLocalHost(state) {
      return window.location.hostname === state.localhost;
    },
  },
};

<template>
  <div class="no-overflow">
    <Loading
      v-if="isLoadAvatar"
      class="no-overflow"
    />
    <template v-else>
      <avatar
        :url="userData.avatar"
        :gender-id="userData.gender_code"
        :fio-separate="[userData.first_name, userData.last_name]"
        :size="$store.state.width < 1024 ? '64' : '164'"
      />

      <label class="btn min-w-fit !px-1">
        <span>Новое фото</span>
        <input
          :style="{display: 'none'}"
          type="file"
          name="image"
          accept="image/png,image/jpg,image/jpeg"
          @change="setAvatar"
        />
      </label>

      <el-button
        v-if="$store.state.width >= 1024 && userData.is_adm"
        class="btn"
        @click="showQR"
      >
        {{ $t($getUiItemCaption(signature, 'AddContact')) }}
      </el-button>
    </template>
    <avatar-editor
      ref="avatar-editor"
      :is-avatar-editor-visible="isAvatarEditorVisible"
      :img-src="avatarSrc"
      @hide-dialog="hideDialog"
      @save-avatar="saveAvatar"
    />
  </div>
</template>

<script>
import avatar from '@/components/common/elkAvatar';
import avatarEditor from '@/components/common/elkAvatarEditor';
import setNotification from '@/utils/setNotification';
import Loading from '@/components/common/elkLoading';

export default {
  name: 'ProfileAvatar',

  components: {
    avatar,
    avatarEditor,
    Loading
  },

  props: ['userData', 'isLoadAvatar', 'signature'],

  data() {
    return {
      isAvatarEditorVisible: false,
      avatarSrc: null
    };
  },

  methods: {
    setNotification,
    hideDialog() {
      this.isAvatarEditorVisible = false;
      setTimeout(() => {
        this.avatarSrc = null;
      }, 0);
    },
    setAvatar(e) {
      const vm = this;

      const file = e.target.files[0];
      const maxFileSize = 2097152;

      if (file.size < maxFileSize) {
        const reader = new FileReader();

        reader.onload = event => {
          const imgUrl = event.target.result;

          const image = new Image();
          image.src = imgUrl;

          image.onerror = function () {
            vm.setNotification({message: 'Не удалось загрузить изображение'});
          };

          image.onload = function () {
            const height = this.height;
            const width = this.width;

            const isSmallResolution = height < 380 || width < 380;
            const isLargeResolution = height > 3000 || width > 3000;

            if (isSmallResolution) {
              vm.setNotification({
                message: 'Минимальное разрешение изображения — 380х380'
              });
            } else if (isLargeResolution) {
              vm.setNotification({
                message: 'Максимальное разрешение изображения — 3000х3000'
              });
            } else {
              vm.isAvatarEditorVisible = true;

              vm.$nextTick().then(() => {
                const cropper = vm.$refs['avatar-editor'].$refs.cropper;
                cropper.enable();
                cropper.replace(imgUrl);

                vm.avatarSrc = imgUrl;
              });
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
        vm.setNotification({
          message: 'Размер фото не должен превышать 2 Mb'
        });
      }

      e.target.value = null;
    },

    saveAvatar(avatar) {
      this.$emit('update-user-avatar', avatar);
    },

    showQR() {
      this.$emit('show-qr');
    }
  }
};
</script>

<style lang="scss">
.avatar {
  margin-bottom: 13px;
}
</style>

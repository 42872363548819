<template>
  <div :style="{color}">
    {{ programName }}
  </div>
</template>

<script>
export default {
  name: "ProgramSpecialization",

  props: {
    specialization: {
      type: String,
      required: true,
    }
  },

  computed: {
    programName () {
      return this.$t(this.specialization)
    },
    color() {
      switch (this.specialization) {
        case 'additional-education':
          return '#6b7a99';
        case 'spec-master':
          return '#d82e2e';
        case 'mba':
          return '#00a35f';
        case 'prof-dev':
          return '#eb6d00';
        case 'prof-retraining':
          return '#008ef6';
        default:
          return '#000000';
      }
    },
  },

  i18n: {
    messages: {
      en: {
        'additional-education': 'Additional education for adults',
        'spec-master': 'Master',
        'mba': 'MBA',
        'prof-dev': 'Professional development ',
        'prof-retraining': 'Professional retraining',
      },
      ru: {
        'additional-education': 'Дополнительное образование для взрослых',
        'spec-master': 'Магистратура',
        'mba': 'MBA',
        'prof-dev': 'Повышение квалификации',
        'prof-retraining': 'Профессиональная переподготовка',
      },
    }
  },
}
</script>
let components = {
  //pages
  'elk.main': () => import(/* webpackChunkName: "pages" */ '@/views/Home'),
  'elk.user_suggestions': () =>
    import(/* webpackChunkName: "pages" */ '@/views/UserSuggestions'),
  'elk.recovery': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkRecoveryPassword'),
  'elk.feedback': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkFeedback'),
  'elk.hr': () => import(/* webpackChunkName: "pages" */ '@/views/elkHr'),
  'elk.news.detail': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkNews'),
  'elk.claim': () => import(/* webpackChunkName: "pages" */ '@/views/elkClaim'),
  'elk.notification': () =>
    import(/* webpackChunkName: "pages" */ '@/views/WidgetsContainer'),
  'elk.task': () => import(/* webpackChunkName: "pages" */ '@/views/elkTask'),
  'elk.sed': () =>
    import(/* webpackChunkName: "pages" */ '@/views/WidgetsContainer'),
  'elk.service': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkService'),
  'elk.schedule': () =>
    import(/* webpackChunkName: "pages" */ '@/views/WidgetsContainer'),
  'elk.faq': () => import(/* webpackChunkName: "pages" */ '@/views/elkFaq'),
  'elk.profile': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkProfile'),
  'elk.hr.detail': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkHrDetail'),
  /*  'elk.registration': () =>
    import(/!* webpackChunkName: "pages" *!/ '@/views/elkRegistration'),*/
  'elk.stat': () => import(/* webpackChunkName: "pages" */ '@/views/elkStat'),
  'elk.body_check': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkBodyCheck'),
  'elk.body_check.detail': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkBodyCheck'),
  'elk.service.strapi.detail': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkServiceStrapiDetail'),
  'elk.service.it.strapi.detail': () =>
    import(/* webpackChunkName: "pages" */ '@/views/elkServiceStrapiDetail'),
  // Новая страница регистрации
  'elk.registration': () =>
    import(/* webpackChunkName: "pages" */ '@/views/reg/registrationPage.vue'),
  'represent.registration': () =>
    import(/* webpackChunkName: "pages" */ '@/views/reg/registrationPage.vue'),
  'represent.profile': () =>
    import('@/views/representProfile/RepresentProfile.vue'),
  'represent.profile.edit': () =>
    import(
      /* webpackChunkName: "pages" */ '@/views/representProfileEdit/RepresentProfileEdit'
    ),
  'represent.profile.edit.legal': () =>
    import(
      /* webpackChunkName: "pages" */ '@/views/representProfileEditLegal/RepresentProfileEditLegal'
    ),
  'represent.list': () =>
    import(
      /* webpackChunkName: "pages" */ '@/views/representList/RepresentList'
    ),
  'represent.legal_listeners': () =>
    import('@/views/RepresentLegalListeners.vue'),
  'represent.documents.acts': () => import('@/views/RepresentDocumentActs.vue'),
  'represent.documents.bills': () =>
    import('@/views/RepresentDocumentBills.vue'),
  'represent.documents.contracts': () =>
    import('@/views/representDocumentList/RepresentDocumentList.vue'),
  'represent.documents.contracts.detail': () =>
    import('@/views/dpoRepresentContract/dpoRepresentContractDetail.vue'),
  'represent.documents.contracts.detail.listeners': () =>
    import('@/views/dpoRepresentListeners/dpoRepresentListeners'),
  'represent.invite': () =>
    import(
      /* webpackChunkName: "pages" */ '@/views/representInvite/RerpresentInvite'
    ),
  'represent.listener.invite': () =>
    import(
      /* webpackChunkName: "pages" */ '@/views/representListenerInvite/RepresentListenerInvite'
    ),
  /* Страницы ДПО */
  // 'dpo.main': () => import('@/views/mainPage/mainPage'),
  //'dpo.profile': () => import('@/views/mainProfile/profilePage'),
  'dpo.application.for.contracts': () =>
    import('@/views/dpoContract/dpoContractPage'),
  'dpo.application.for.contracts.detail': () =>
    import('@/views/dpoCorp/contractApplicationsDetail'),
  'dpo.new.application.form': () =>
    import('@/views/dpoCorp/dpoNewApplicationDetail'),
  'dpo.new.application': () => import('@/views/dpoCorp/dpoNewApplication'),
  'represent.programs': () =>
    import('@/views/dpoCorp/dpoRepresentApplications'),
  'dpo.programs.list': () => import('@/views/mainPrograms/programsPage'),
  'dpo.programs.selected': () => import('@/views/programs/SelectedPrograms'),
  'dpo.programs.recommended': () =>
    import('@/views/programs/RecommendedPrograms'),
  'dpo.programs.comparison': () =>
    import('@/views/mainComparison/comparisonPage'),
  'dpo.programs.viewing_history': () =>
    import('@/views/programs/ProgramViewingHistory'),
  'dpo.documents': () => import('@/views/mainDocuments/documentsPage'),
  'dpo.basket': () => import('@/views/mainBasket/NewBasket'),
  'dpo.order': () => import('@/views/mainOrder/Order'),
  'dpo.order.success': () =>
    import('@/views/congratulations/CongratulationOnPayment'),
  'dpo.reviews': () => import('@/views/mainReview/reviewPage'),
  'dpo.reviews.finish': () => import('@/views/mainReview/reviewFinish'),
  'dpo.corp.services': () => import('@/views/Services'),
  'dpo.services': () => import('@/views/Services'),
  'dpo.services.requests': () => import('@/views/dpoCorp/dpoMyAppeals'),
  'dpo.get_help': () => import('@/views/mainReference/referencePage'),
  'dpo.corp.get_help': () => import('@/views/mainReference/referencePage'),
  'dpo.my_schedule': () => import('@/views/schedule/MySchedule'),
  'dpo.notifications': () => import('@/views/Notifications'),
  'elk.camunda.detail': () =>
    import(/* webpackChunkName: "pages" */ '@/views/formilyView'),
  'elk.camunda.*': () =>
    import(/* webpackChunkName: "pages" */ '@/views/formilyView'),
  'elk.contract_payment.detail': () =>
    import('@/views/elkContractPaymentDetail')
};

export default signature => {
  const matchingKey = Object.keys(components).find(key => {
    // Заменяем все точки на литерал точки в регулярном выражении и звездочки на "любое количество любых символов"
    const pattern = key
      .split('.')
      .map(part => (part === '*' ? '.*' : part.replace(/\*/g, '[^\\.]*')))
      .join('\\.');
    const regex = new RegExp(`^${pattern}$`);
    return regex.test(signature);
  });

  if (matchingKey) {
    return components[matchingKey];
  } else {
    return () =>
      import(/* webpackChunkName: "pages" */ '@/views/WidgetsContainer');
  }
};

<template>
  <div class="profile__born">
    <p class="profile__table-title">
      {{ $t(getCommonCaptionByName('contacts_birthDate')) }}
    </p>
    <div class="profile__table-info">
      <div
        v-if="!editorBirthDate"
        class="profile__contacts-container"
      >
        <span class="profile__contacts-date">
          {{ bornDate }}
        </span>
        <button
          v-if="isShowEditorBirthDate"
          class="btn btn--secondary btn--icon"
          @click="toggleEditorBirthDate"
        >
          <edit class="edit-icon" />
        </button>
        <el-select
          @change="setShowType"
          v-model.number="value"
          class="profile__contacts-select !max-w-fit"
        >
          <el-option
            :value="1"
            :label="$t(getUiItemCaption(signature, 'fullDateFormatLabel'))"
            >{{
              $t(getUiItemCaption(signature, 'fullDateFormatLabel'))
            }}</el-option
          >
          <el-option
            :value="2"
            :label="$t(getUiItemCaption(signature, 'Day&MonthFormatLabel'))"
            >{{
              $t(getUiItemCaption(signature, 'Day&MonthFormatLabel'))
            }}</el-option
          >
          <el-option
            :value="3"
            :label="$t(getUiItemCaption(signature, 'dontShowDateFormatLabel'))"
            >{{
              $t(getUiItemCaption(signature, 'dontShowDateFormatLabel'))
            }}</el-option
          >
        </el-select>
      </div>
      <elk-editor-birth-date
        v-else
        :placeholder="$t(getCommonCaptionByName('date_format_similar'))"
        :default-mask="true"
        @toggle-editor-birthDate="toggleEditorBirthDate"
        @update-info="updateInfo"
      />
    </div>
  </div>
</template>

<script>
import setNotification from '@/utils/setNotification';
import {getUiItemCaption, getCommonCaptionByName} from '@/i18n/utils';
import Edit from '@/assets/icons/edit.svg?inline';
import ElkEditorBirthDate from './editorBirthDate';
import {apiSetBirthDateShowType} from './api';
import sortFilterDrop from '@/utils/sortDate';

export default {
  name: 'born-date',
  inject: ['signature'],
  components: {
    ElkEditorBirthDate,
    Edit
  },
  props: ['birthDate', 'showType', 'isAdm'],
  data() {
    return {
      value: this.showType,
      editorBirthDate: false
    };
  },
  computed: {
    profileFillErrors() {
      let errors = this.$store.getters[
        'profileFillCheck/profileFillErrors'
      ].map(function (obj) {
        return obj.code;
      });
      if (errors?.includes('birthday')) return true;
    },
    bornDate() {
      if (this.birthDate) {
        try {
          return this.$d(new Date(this.birthDate), 'birthDate', 'ru');
        } catch (e) {
          //sentry
          return '-';
        }
      } else {
        return '-';
      }
    },
    isShowEditorBirthDate() {
      if (this.isAdm && !this.birthDate) {
        return true;
      } else if (!this.isAdm) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    getUiItemCaption,
    getCommonCaptionByName,
    setNotification,
    apiSetBirthDateShowType,
    toggleEditorBirthDate() {
      this.editorBirthDate = !this.editorBirthDate;
    },
    updateInfo(value) {
      this.$emit('update-info', value);
    },
    async setShowType(type) {
      try {
        let res = await this.apiSetBirthDateShowType(type);

        if (res.status == 200) {
          this.$emit('update-info', {
            type: 'show-birthdate-type',
            value: type
          });
        }
      } catch (err) {
        if (!err.isHandled) {
          this.setNotification({
            message: this.$t(
              this.getUiItemCaption(
                this.signature,
                'failSettingBirthDateVIsibilityFormat'
              )
            )
          });
        }
      }
    }
  }
};
</script>

<template>
  <section
    id="elk-profile-main"
    class="widget"
  >
    <div class="widget__header">
      <h2 class="h4">
        {{ widgetData.block_name }}
      </h2>
    </div>
    <div class="widget__content">
      <loading v-if="isLoad" />
      <error-message v-if="isErr" />
      <div
        v-if="userData"
        class="widget__body"
      >
        <article class="profile">
          <profile-info
            :is-load-avatar="isLoadAvatar"
            :user-data="userData"
            @update-info="updateInfo"
            @update-mobile-show="updateMobileShow"
            @update-email-show="updateEmailShow"
            @set-new-avatar="setNewAvatar"
          />
          <profile-error v-if="$store.state.width < 1024" />
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import {mapMutations, mapGetters} from 'vuex';
import {setAvatar} from '@/store/mutationsName';
import {
  GET_PROFILE,
  GET_PROFILE_REFERENCES,
  PROFILE_V1,
  SET_AVATAR,
  SET_PROFILE_BIRTHDATE
} from '@/api';
import setNotification from '@/utils/setNotification';
import profileError from './profileError';
import Loading from '@/components/common/elkLoading';
import ErrorMessage from '@/components/common/elkErrorMessage';
import ProfileInfo from './profileInfo/index';
import localization from '@/i18n/localization';
import {getUiItemCaption} from '@/i18n/utils';

export default {
  name: 'ElkProfileMain',
  components: {
    ProfileInfo,
    profileError,
    Loading,
    ErrorMessage
  },
  provide() {
    return {
      signature: this.widgetData.signature,
      localization: this.localization
    };
  },
  props: {
    widgetData: Object
    /*additionalFields: { type:Object, default: () => ({})},*/
  },
  data() {
    return {
      userData: null,
      isErr: false,
      isLoad: false,
      isLoadAvatar: false,
      localization: localization(this.widgetData.signature),
      categoryListener: [],
      initialData: true
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    })
  },
  /*watch: {
    additionalFields: {
      handler(v) {
        this.userData = { ...this.userData, ...v };
      },
      deep: true,
    }
  },*/
  methods: {
    ...mapMutations([setAvatar]),
    setNotification,
    showNotify(message, typeMessage = 'info') {
      const h = this.$createElement;

      this.$notify({
        title: 'Сообщение',
        type: typeMessage,
        message: h('i', {}, message)
      });
    },
    updateInfo(v) {
      if (v.type === 'email') {
        this.userData.email_personal = v.value;
      }
      if (v.type === 'phone') {
        this.updateProfile({phone: v.value});
        this.userData.phone_mobile = v.value;
      }
      if (v.type === 'nationality') {
        this.userData.nationality = v.value;
      }
      if (v.type === 'show-birthdate-type') {
        this.userData.birth_date_show_type_id = v.value;
      }
      if (v.type === 'gender') {
        /** @type { id: Number, name: String } */
        this.updateProfile({gender_id: v.value.id});
        this.userData.gender = v.value;
      }
      if (v.type === 'new-birthdate') {
        /** @type String */
        this.updateProfile({birthdate: v.value});
        this.userData.birth_date = v.value;
      }
      /*if (v.type === 'category_listener') {
        this.userData.category_listener = v.value;
      }*/
    },
    async setProfileBirthdate(payload) {
      try {
        await Axios.post(SET_PROFILE_BIRTHDATE, {
          birth_date: payload.birthdate
        });
        setNotification({
          message: `Дата рождения изменена`,
          type: 'success'
        });
      } catch (e) {
        this.responseErrorHandler(e);
      }
    },
    async getUserData() {
      try {
        this.isLoad = true;
        let {data: userData} = await Axios.get(GET_PROFILE);
        this.userData = userData;
      } catch {
        this.isErr = true;
      } finally {
        this.isLoad = false;
      }
    },
    async setNewAvatar(avatar) {
      try {
        this.isLoadAvatar = true;
        const response = await Axios.post(SET_AVATAR, {
          photo: avatar
        });

        if (response.status === 200) {
          this.userData.avatar = avatar;
          this[setAvatar](avatar);
        } else {
          throw new Error();
        }
      } catch (err) {
        if (!err.isHandled) {
          this.setNotification({
            message: 'Не удалось установить новый аватар. Попробуйте позже.'
          });
        }
      } finally {
        this.isLoadAvatar = false;
      }
    },
    updateEmailShow(v) {
      this.userData.is_email_personal_show = v.value;
    },
    updateMobileShow(v) {
      this.userData.is_phone_mobile_show = v.value;
    },
    responseErrorHandler(error) {
      if (!error.response) {
        return;
      }

      if (error.response.data?.message) {
        this.showNotify(error.response.data.message, 'error');
      }
    },
    async updateProfile(postBody) {
      try {
        await Axios.patch(PROFILE_V1, postBody);
        await this.setProfileBirthdate(postBody);
        await this.$store.dispatch('profileFillCheck/getProfileFillCheck');
      } catch (e) {
        this.responseErrorHandler(e);
      }
    }
    /*    prepareList(list) {
      if (typeof list === 'object') {
        list = Object.values(list);
      }

      return list.length ? list.map(({value: id, label: name}) => ({name, id})) : [];
    },
    async getProfileReferences() {
      if(this.$store.getters.userDpo) {
        const {data} = await Axios.get(GET_PROFILE_REFERENCES);
        this.categoryListener = this.prepareList(data.data.category_listener);
      }
    },*/
    /*setProfileData(data) {
      const categoryListener = typeof data.category_listener === 'number' ? this.categoryListener.filter((item) => item.id === data.category_listener)[0] : {};
      this.forms.profile = {
        'category_listener': {...categoryListener},
      };

      this.$store.dispatch('profileData/setWork', data.job);
      this.$store.dispatch('profileData/setPost', data.post);

      this.initialData = false;
    },
    async getProfileData() {
      try {
        const {data} = await Axios.get(PROFILE_V1);

        if (data.code === 200 && data.data) {
          this.setProfileData(data.data);
        }
      } catch {
        /!** *!/
      }
    },*/
  },
  async created() {
    await this.getUserData();
    if (this.$store.getters.userDpo) {
      //await this.getProfileReferences();
      await this.$store.dispatch('profileData/getProfileListenerCategories');
      await this.$store.dispatch('profileFillCheck/getProfileFillCheck');
      await this.$store.dispatch('profileData/getProfileData');
      //await this.getProfileData();
    }
  }
};
</script>
